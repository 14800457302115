<template>
  <div class="col-md-3">
    <div class="card module-card">
      <img
        v-if="schedule.image != null"
        :src="schedule.image"
        class="card-img-top"
        alt="..."
      />
      <img v-else src="../assets/module-illus.png" class="card-img-top" />
      <div class="card-body">
        <router-link
          :to="{ name: 'MyCourseDetails', params: { id: schedule.program.id } }"
        >
          <h5 class="card-title">{{ schedule.program.program_title }}</h5>
        </router-link>
        <p class="category-line d-flex pb-2">
          <span class="_subject">
            {{ schedule.subject.subject_title }}
          </span>
        </p>
        <p class="card-text">
          <span>
            <i class="far fa-clock"></i>
          </span>
          {{ convertedTimeForUser }}
        </p>
        <p>Recordings {{ schedule.recordings_count }}</p>
        <router-link
          :to="{ name: 'recordings', params: { id: schedule.schedule_id } }"
          class="cta-play"
        >
          <i class="fas fa-play"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Timezone from "@/services/Timezone";
export default {
  name: "CourseHistoryCard",
  props: ["schedule"],
  computed: {
    convertedTimeForUser() {
      let convertedDay = Timezone.convertedTimeZone(
        this.schedule.start_time
      ).format("ddd, Do MMM, YYYY");
      let convertedStartTime = Timezone.convertedTimeZone(
        this.schedule.start_time
      ).format("h:mm A");
      let convertedEndTime = Timezone.convertedTimeZone(
        this.schedule.end_time
      ).format("h:mm A");
      let convertedTime =
        convertedDay + " | " + convertedStartTime + " - " + convertedEndTime;
      return convertedTime;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/module-card.scss";
</style>
