<template>
  <div class="course-tab resource-section">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="mycourse-tab"
          data-bs-toggle="tab"
          data-bs-target="#mycourse"
          type="button"
          role="tab"
          aria-controls="mycourse"
          aria-selected="true"
        >
          subscribed courses
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="schedule-tab"
          data-bs-toggle="tab"
          data-bs-target="#schedule"
          type="button"
          role="tab"
          aria-controls="schedule"
          aria-selected="true"
        >
          schedule
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="history-tab"
          data-bs-toggle="tab"
          data-bs-target="#history"
          type="button"
          role="tab"
          aria-controls="history"
          aria-selected="false"
        >
          history
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="exam-tab"
          data-bs-toggle="tab"
          data-bs-target="#exam"
          type="button"
          role="tab"
          aria-controls="exam"
          aria-selected="false"
        >
          Exams
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="assignment-tab"
          data-bs-toggle="tab"
          data-bs-target="#assignment"
          type="button"
          role="tab"
          aria-controls="assignment"
          aria-selected="false"
        >
          Assignments
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="mycourse"
        role="tabpanel"
        aria-labelledby="mycourse-tab"
      >
        <h6 class="day-header">All Subscribed Courses</h6>
        <div class="row listing-row">
          <div v-if="programLoading" class="loading">
            <circular-loader></circular-loader>
          </div>
          <CourseCard
            v-else-if="subscribedPrograms"
            v-for="program in subscribedPrograms"
            :key="program.id"
            :program="program"
            :toggleImage="true"
            :toggleBanner="false"
            :togglePrice="false"
            :toggleFilteredWeeks="false"
            :colValue="colval"
            routeLink="SubscribedCourseActivities"
          />
          <EmptyList
            v-if="totalCourses == 0 && programLoading == false"
            messageText="No subscribed courses found!"
          />
        </div>
        <div class="paging-wrapper py-3">
          <v-pagination
            v-model="coursePage"
            :pages="totalCoursePages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="coursePaginateUpdateHandler"
            v-if="totalCourses > 0"
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="schedule"
        role="tabpanel"
        aria-labelledby="schedule-tab"
      >
        <h6 class="day-header">Live/Upcoming Classes</h6>
        <ul class="list-unstyled filter-row">
          <li>
            <select
              class="form-control"
              v-model="programId"
              @change="getSubjects"
            >
              <option value="">Select Program</option>
              <option
                v-for="(assignedProgram, assignedIndex) in assignedPrograms"
                :key="assignedIndex"
                :value="assignedProgram.id"
              >
                {{ assignedProgram.program_title }}
              </option>
            </select>
          </li>
          <li>
            <select class="form-control" v-model="subjectId">
              <option value="">Select Subject</option>
              <option
                v-for="(subject, subjectIndex) in subjects"
                :key="subjectIndex"
                :value="subject.subject.id"
              >
                {{ subject.subject.subject_title }}
              </option>
            </select>
          </li>
          <li>
            <v-date-picker v-model="fromDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="form-control"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Start Date"
                />
              </template>
            </v-date-picker>
          </li>
          <li>
            <v-date-picker v-model="toDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="form-control"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="End Date"
                />
              </template>
            </v-date-picker>
          </li>
        </ul>
        <div class="row listing-row">
          <div v-if="upcomingScheduleLoading" class="loading">
            <circular-loader></circular-loader>
          </div>
          <CourseScheduleCard
            v-for="upcoming in upcomingSchedules"
            :key="upcoming.schedule_id"
            :schedule="upcoming"
            v-else-if="upcomingSchedules"
          />

          <EmptyList
            v-if="
              totalUpcomingSchedules == 0 && upcomingScheduleLoading == false
            "
            messageText="No schedules found!"
          />
        </div>
        <div class="paging-wrapper py-3">
          <v-pagination
            v-model="upcomingSchedulePage"
            :pages="totalUpcomingSchedulePages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="upcomingSchedulePaginateUpdateHandler"
            v-if="totalUpcomingSchedules > 0"
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="history"
        role="tabpanel"
        aria-labelledby="history-tab"
      >
        <h6 class="day-header">History</h6>
        <ul class="list-unstyled filter-row">
          <li>
            <select
              class="form-control"
              v-model="programId"
              @change="getSubjects"
            >
              <option value="">Select Program</option>
              <option
                v-for="(assignedProgram, assignedIndex) in assignedPrograms"
                :key="assignedIndex"
                :value="assignedProgram.id"
              >
                {{ assignedProgram.program_title }}
              </option>
            </select>
          </li>
          <li>
            <select class="form-control" v-model="subjectId">
              <option value="">Select Subject</option>
              <option
                v-for="(subject, subjectIndex) in subjects"
                :key="subjectIndex"
                :value="subject.subject.id"
              >
                {{ subject.subject.subject_title }}
              </option>
            </select>
          </li>
          <li>
            <v-date-picker v-model="fromDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="form-control"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="Start Date"
                />
              </template>
            </v-date-picker>
          </li>
          <li>
            <v-date-picker v-model="toDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="form-control"
                  :value="inputValue"
                  v-on="inputEvents"
                  placeholder="End Date"
                />
              </template>
            </v-date-picker>
          </li>
        </ul>
        <div class="row listing-row">
          <div v-if="expiredScheduleLoading" class="loading">
            <circular-loader></circular-loader>
          </div>
          <CourseHistoryCard
            v-for="expired in expiredSchedules"
            :key="expired.schedule_id"
            :schedule="expired"
            v-else-if="expiredSchedules"
          />
          <div class="paging-wrapper py-3">
            <v-pagination
              v-model="expiredSchedulePage"
              :pages="totalExpiredSchedulePages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="expiredSchedulePaginateUpdateHandler"
              v-if="totalExpiredSchedules > 0"
            />
          </div>
          <EmptyList
            v-if="totalExpiredSchedules == 0 && expiredScheduleLoading == false"
            messageText="No recent schedules found!"
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="exam"
        role="tabpanel"
        aria-labelledby="exam-tab"
      >
        <h6 class="day-header">Exams</h6>
        <ul class="list-unstyled filter-row">
          <li>
            <select class="form-control" v-model="programId">
              <option value="">Select Program</option>
              <option
                v-for="(assignedProgram, assignedIndex) in assignedPrograms"
                :key="assignedIndex"
                :value="assignedProgram.id"
              >
                {{ assignedProgram.program_title }}
              </option>
            </select>
          </li>
        </ul>
        <div class="row listing-row">
          <div v-if="examListLoading" class="loading">Loading...</div>
          <div v-else-if="examLists">
            <ul class="list-unstyled assignment-list exam-list">
              <li v-for="exam in examLists" :key="exam.id">
                <p>{{ exam.title }}</p>
                <span v-if="exam && exam.exam_subjects != null"
                  ><span v-if="exam.exam_subjects.subject != null">
                    Subject:
                    {{ exam.exam_subjects[0].subject.subject_title }}</span
                  >
                  <span v-if="exam.subject_count > 1">
                    + {{ exam.subject_count - 1 }}
                  </span>
                </span>
                <span v-if="exam.converted_date"
                  >Starts On: {{ exam.converted_date }}</span
                >
                <span>Total Duration: {{ exam.duration }} Min</span>
                <!-- <router-link
                  :to="{
                    name: 'CourseExam',
                    params: { program_id: exam.program_id, exam_id: exam.id },
                  }"
                  v-if="exam.status === 'START' && exam.exam_attended === false"
                  class="btn small-cta"
                  >Attend</router-link
                > -->
                <router-link
                  :to="{
                    name: 'CourseExamInstructions',
                    params: { program_id: exam.program_id, exam_id: exam.id },
                  }"
                  v-if="exam.status === 'START' && exam.exam_attended === false"
                  class="btn small-cta"
                  >Attend</router-link
                >
                <!-- <router-link
                  :to="{
                    name: 'CourseExam',
                    params: { program_id: exam.program_id, exam_id: exam.id },
                  }"
                  v-else-if="
                    exam.status === 'START' &&
                    exam.exam_attended === true &&
                    exam.parent_exam_id > 0 &&
                    exam.is_reattendable == 1
                  "
                  class="btn small-cta"
                  >Attend</router-link
                > -->
                <router-link
                  :to="{
                    name: 'CourseExamInstructions',
                    params: { program_id: exam.program_id, exam_id: exam.id },
                  }"
                  v-else-if="
                    exam.status === 'START' &&
                    exam.exam_attended === true &&
                    exam.parent_exam_id > 0 &&
                    exam.is_reattendable == 1
                  "
                  class="btn small-cta"
                  >Attend</router-link
                >
                <router-link
                  :to="{
                    name: 'CourseExamInstructions',
                    params: { program_id: exam.program_id, exam_id: exam.id },
                  }"
                  v-else-if="
                    (exam.status === 'START' && exam.exam_attended === true) ||
                    (exam.status === 'END' && exam.exam_attended === true)
                  "
                  class="btn small-cta attended"
                  >Result</router-link
                >
                <a
                  href="javascript:void(0);"
                  v-else-if="exam.status === 'PUBLISHED'"
                  class="btn small-cta pending"
                  >Not Scheduled</a
                >
                <a
                  href="javascript:void(0);"
                  v-else-if="exam.status === 'END'"
                  class="btn small-cta ended"
                  >Ended</a
                >
              </li>
            </ul>
            <div class="paging-wrapper py-3">
              <v-pagination
                v-model="examListPage"
                :pages="totalExamListPages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="examListPaginateUpdateHandler"
                v-if="totalExamLists > 0"
              />
            </div>
            <EmptyList
              v-if="totalExamLists == 0"
              messageText="No exams found!"
            />
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="assignment"
        role="tabpanel"
        aria-labelledby="assignment-tab"
      >
        <h6 class="day-header">Assignments</h6>
        <ul class="list-unstyled filter-row">
          <li>
            <select class="form-control" v-model="programId">
              <option value="">Select Program</option>
              <option
                v-for="(assignedProgram, assignedIndex) in assignedPrograms"
                :key="assignedIndex"
                :value="assignedProgram.id"
              >
                {{ assignedProgram.program_title }}
              </option>
            </select>
          </li>
        </ul>
        <div class="row listing-row">
          <div v-if="assignmentLoading" class="loading">Loading...</div>
          <div v-else-if="assignments">
            <ul v-if="assignments" class="list-unstyled assignment-list">
              <li v-for="assignment in assignments" :key="assignment.id">
                <p>{{ assignment.title }}</p>
                <p v-if="assignment.submitted_status == 'DRAFT'">Draft</p>
                <p v-if="assignment.submitted_status == 'PUBLISHED'">
                  Submitted
                </p>
                <span>{{ convertedTimeForUser(assignment.last_date) }}</span>
                <router-link
                  :to="{
                    name: 'CourseAssignment',
                    params: {
                      program_id: assignment.program_id,
                      assignment_id: assignment.id,
                    },
                  }"
                  class="btn small-cta"
                  >Details
                </router-link>
              </li>
            </ul>
            <div class="paging-wrapper py-3">
              <v-pagination
                v-model="assignmentPage"
                :pages="totalAssignmentPages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="assignmentPaginateUpdateHandler"
                v-if="totalAssignments > 0"
              />
            </div>
            <EmptyList
              v-if="totalAssignments == 0"
              messageText="No assignments found!"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import CourseScheduleCard from "@/components/CourseScheduleCard.vue";
import CourseHistoryCard from "@/components/CourseHistoryCard.vue";
import EmptyList from "@/components/EmptyList.vue";
import DashboardService from "@/services/DashboardService";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import moment from "moment";
import CircularLoader from "@/components/loaders/CircularLoader.vue";
import ExamService from "@/services/ExamService";
import Timezone from "@/services/Timezone";
import AssignmentService from "@/services/AssignmentService";

export default {
  name: "CourseTab",
  components: {
    CourseCard,
    CourseScheduleCard,
    CourseHistoryCard,
    VPagination,
    EmptyList,
    CircularLoader,
  },
  watch: {
    toDate: {
      handler: function () {
        this.upcomingSchedulePage = 1;
        this.currentUpcomingSchedulePage = 1;
        this.expiredSchedulePage = 1;
        this.currentExpiredSchedulePage = 1;
        this.fetchUpcomingSchedules();
        this.fetchExpiredSchedules();
      },
      deep: true,
    },
    fromDate: {
      handler: function () {
        this.upcomingSchedulePage = 1;
        this.currentUpcomingSchedulePage = 1;
        this.expiredSchedulePage = 1;
        this.currentExpiredSchedulePage = 1;
        this.fetchUpcomingSchedules();
        this.fetchExpiredSchedules();
      },
      deep: true,
    },
    subjectId: {
      handler: function () {
        this.upcomingSchedulePage = 1;
        this.currentUpcomingSchedulePage = 1;
        this.expiredSchedulePage = 1;
        this.currentExpiredSchedulePage = 1;
        this.fetchUpcomingSchedules();
        this.fetchExpiredSchedules();
      },
      deep: true,
    },
    programId: {
      handler: function () {
        this.upcomingSchedulePage = 1;
        this.currentUpcomingSchedulePage = 1;
        this.expiredSchedulePage = 1;
        this.currentExpiredSchedulePage = 1;
        this.fetchUpcomingSchedules();
        this.fetchExpiredSchedules();
        this.fetchAllSubscribedProgramExams();
        this.fetchAllSubscribedProgramAssignments();
      },
      deep: true,
    },
  },
  data() {
    return {
      programLoading: true,
      upcomingScheduleLoading: true,
      expiredScheduleLoading: true,
      // Initial page number for program if any
      coursePage: 1,
      currentCoursePage: 1,
      totalCoursePages: 0,
      totalCourses: 0,
      subscribedPrograms: [],
      // Initial page number for upcoming schedules if any
      upcomingSchedulePage: 1,
      currentUpcomingSchedulePage: 1,
      totalUpcomingSchedulePages: 0,
      totalUpcomingSchedules: 0,
      upcomingSchedules: [],
      // Initial page number for expired schedules if any
      expiredSchedulePage: 1,
      currentExpiredSchedulePage: 1,
      totalExpiredSchedulePages: 0,
      totalExpiredSchedules: 0,
      expiredSchedules: [],
      colval: "col-md-4",

      assignedPrograms: [],
      subjects: [],
      subjectId: "",
      programId: "",
      fromDate: "",
      toDate: "",
      masks: {
        input: "DD-MM-YYYY",
      },
      examListLoading: true,
      examListPage: 1,
      currentExamListPage: 1,
      totalExamListPages: 0,
      totalExamLists: 0,
      examLists: [],
      assignmentLoading: true,
      assignmentPage: 1,
      currentAssignmentPage: 1,
      totalAssignmentPages: 0,
      totalAssignments: 0,
      assignments: [],
    };
  },
  mounted() {
    this.fetchSubscribedPrograms();
    this.fetchUpcomingSchedules();
    this.fetchExpiredSchedules();
    this.fetchAllSubscribedProgramExams();
    this.fetchAllSubscribedProgramAssignments();
  },
  methods: {
    fetchSubscribedPrograms() {
      const params = {
        page: this.currentCoursePage,
      };
      DashboardService.getSubscribedPrograms(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.programLoading = false;
            let programData = response.data.data.data;
            if (programData.length > 0) {
              this.subscribedPrograms = [];
              programData.map((subscribedProgram) => {
                if (subscribedProgram.batch.program != null) {
                  subscribedProgram.batch.program.subscription_status =
                    subscribedProgram.is_active;
                  this.subscribedPrograms.push(subscribedProgram.batch.program);
                }
              });
            }
            let programfilter = response.data.filter_datas; //only for program wise filtering
            if (programfilter.length > 0) {
              this.filterProgram = [];
              programfilter.map((filter) => {
                if (filter.batch.program != null) {
                  filter.batch.program.subscription_status = filter.is_active;
                  this.filterProgram.push(filter.batch.program);
                }
              });
            }
            this.assignedPrograms = this.filterProgram;
            this.totalCoursePages = response.data.data.last_page;
            this.currentCoursePage = response.data.data.current_page;
            this.totalCourses = response.data.data.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSubjects: function () {
      this.subjectId = "";
      if (this.programId == "") {
        this.subjects = [];
      } else {
        const subjects = this.assignedPrograms
          .filter((obj) => {
            return obj.id === this.programId;
          })
          .pop();
        this.subjects = subjects.program_subjects;
      }
    },
    fetchUpcomingSchedules() {
      const params = {
        page: this.currentUpcomingSchedulePage,
        program_id: this.programId,
        subject_id: this.subjectId,
        from_date: this.getDate(this.fromDate),
        to_date: this.getDate(this.toDate),
      };
      DashboardService.getUpcomingSchedules(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.upcomingScheduleLoading = false;
            this.upcomingSchedules = response.data.upcoming_schedules.data;
            // works only when totalUpcomingSchedulePages should be the last_page value
            this.totalUpcomingSchedulePages =
              response.data.upcoming_schedules.last_page;
            this.currentUpcomingSchedulePage =
              response.data.upcoming_schedules.current_page;
            this.totalUpcomingSchedules =
              response.data.upcoming_schedules.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchExpiredSchedules() {
      const params = {
        page: this.currentExpiredSchedulePage,
        program_id: this.programId,
        subject_id: this.subjectId,
        from_date: this.getDate(this.fromDate),
        to_date: this.getDate(this.toDate),
      };

      DashboardService.getExpiredSchedules(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.expiredScheduleLoading = false;
            this.expiredSchedules = response.data.expired_schedules.data;
            // works only when totalExpiredSchedulePages should be the last_page value
            this.totalExpiredSchedulePages =
              response.data.expired_schedules.last_page;
            this.currentExpiredSchedulePage =
              response.data.expired_schedules.current_page;
            this.totalExpiredSchedules = response.data.expired_schedules.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchAllSubscribedProgramExams() {
      let params = {
        page: this.currentExamListPage,
        program_id: this.programId,
        // slug: slug,
      };
      // console.log(params);
      await ExamService.getAllSubscribedProgramExams(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.examLists = response.data.exams.data;
            // this.examLists.exam_subjects = null;
            // console.log(this.examLists.exam_subjects.subject);
            // works only when totalExamListPages should be the last_page value
            this.totalExamListPages = response.data.exams.last_page;
            this.currentExamListPage = response.data.exams.current_page;
            this.totalExamLists = response.data.exams.total;
            this.examListLoading = false;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchAllSubscribedProgramAssignments() {
      let params = {
        page: this.currentAssignmentPage,
        program_id: this.programId,
        // slug: slug,
      };
      // console.log(params);
      await AssignmentService.getAllSubscribedProgramAssignments(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.assignmentLoading = false;
            this.assignments = response.data.assignments.data;
            // console.log(this.assignments);
            // works only when totalAssignmentPages should be the last_page value
            this.totalAssignmentPages = response.data.assignments.last_page;
            this.currentAssignmentPage = response.data.assignments.current_page;
            this.totalAssignments = response.data.assignments.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    convertedTimeForUser(date) {
      let convertedDay =
        Timezone.convertedTimeZone(date).format("ddd, Do MMM, YYYY");
      let convertedTime = "Last date to submit: " + convertedDay;
      return convertedTime;
    },
    coursePaginateUpdateHandler(selectedPage) {
      if (this.currentCoursePage !== selectedPage) {
        this.currentCoursePage = selectedPage;
        this.fetchSubscribedPrograms();
      }
    },
    upcomingSchedulePaginateUpdateHandler(selectedPage) {
      if (this.currentUpcomingSchedulePage !== selectedPage) {
        this.currentUpcomingSchedulePage = selectedPage;
        this.fetchUpcomingSchedules();
      }
    },
    expiredSchedulePaginateUpdateHandler(selectedPage) {
      if (this.currentExpiredSchedulePage !== selectedPage) {
        this.currentExpiredSchedulePage = selectedPage;
        this.fetchExpiredSchedules();
      }
    },
    examListPaginateUpdateHandler(selectedPage) {
      if (this.currentExamListPage !== selectedPage) {
        this.currentExamListPage = selectedPage;
        this.fetchAllSubscribedProgramExams();
      }
    },
    assignmentPaginateUpdateHandler(selectedPage) {
      if (this.currentAssignmentPage !== selectedPage) {
        this.currentAssignmentPage = selectedPage;
        this.fetchAllSubscribedProgramAssignments();
      }
    },
    getDate(date) {
      return moment(String(date)).format("DD-MM-YYYY");
    },
  },
};
</script>

<style lang="scss">
@import "@/style/course-tab.scss";
@import "@/style/class-single.scss";
</style>
